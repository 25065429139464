.badge {
   position: relative;
   width: 16px;
   height: 16px;
   background: #E63E33;
   border-radius: 100em;
   box-shadow: 0 1px 5px rgba(0, 0, 0, 0.28);
   transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.1);
}

.badge.pulse::before,
.badge.pulse::after {
   content: '';
   position: absolute;
   border: black solid 3px;
   border-radius: 100em;
   width: 100%;
   height: 100%;
   top: -3px;
   left: -3px;
   margin: auto;
   opacity: 0;
}

section:hover .badge.pulse,
section.active .badge.pulse {
   animation: scale 3s cubic-bezier(0.175, 0.885, 0.32, 1.3) infinite;
}

section:hover .badge.pulse:before,
section:hover .badge.pulse:after,
section.active .badge.pulse:before,
section.active .badge.pulse:after {
   animation: pulse 3s cubic-bezier(0.175, 0.885, 0.32, 1.1) infinite;
   animation-delay: 400ms;
}

section:hover .badge.pulse::before,
section.active .badge.pulse::before {
   animation-delay: 600ms;
}

@keyframes scale {

   0%,
   15% {
      transform: scale(1);
   }

   5%,
   10% {
      transform: scale(1.1);
   }
}

@keyframes pulse {
   0% {
      opacity: .2;
      transform: scale(1);
   }

   50% {
      opacity: 0;
      transform: scale(1.3);
   }
}