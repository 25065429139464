* {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 16px;
  /* Media query put here to change the font-size so all the rem values used on the app for font-size and spacing are responsive */
  @media only screen and (max-width: 1280px) {
    font-size: 15.2px;
  }
  /* Disable all text selection across the app */
  user-select: none;
  -webkit-user-select: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'dr-medium', Arial, Helvetica, sans-serif;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1.light, h2.light, h3.light, h4.light, h5.light, h6.light {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: lighter;
}

body {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  margin:0;
}

.rotate {
  transform: translateX(-50%) translateY(-50%) scaleX(var(--fgmtn-scale-x)) scaleY(var(--fgmtn-scale-y)) rotate(var(--fgmtn-rotation));
  animation: rotation 600ms 0ms infinite forwards;
  /* animation: rotation 2s infinite linear; */
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
